import { combineReducers } from 'redux';
import { homeReducer } from './home';
import { setIndexReducer } from './indexGrab';
import { errorReducer } from './error';

const appReducer = combineReducers({
    home: homeReducer,
    indexGrab: setIndexReducer,
    error: errorReducer,
});

const initialState = {};

const rootReducer = (state = initialState, action) => (appReducer(state, action));

export default rootReducer;

// @flow
import { get } from 'lodash';
import { actionTypes as errorActionTypes } from '../modules/error';
import { put } from 'redux-saga/effects';
import history from '../history';

export default function* handleApiException (error: any) {
  // console.log('une erreur est survenue', get(error, 'response.body.code'));
  switch (get(error, 'response.body.code')) {
    case 'ERR_TECH_MS_CAMPAIGN_LINE_ALREADY_ANSWERED':
      yield put({ type: errorActionTypes.ERROR_ACTION, error: 'compaignAlreadyAnsweredOrConfirmed' });
      yield history.replace("/handleException");
      break;
    case 'ERR_TECH_MS_CAMPAIGN_INVALID_TOKEN':
    case 'ERR_TECH_MS_CAMPAIGN_TOKEN_EXPIRED':
      yield put({ type: errorActionTypes.ERROR_ACTION, error: 'invalidToken' });
      yield history.replace('/handleException');
      break;
    case 'ERR_TECH_MS_CAMPAIGN_OCR_PROCESS_FAILED':
      yield put({ type: errorActionTypes.ERROR_ACTION, error: 'croppImageServerError' });
      break;
    case 'ERR_TECH_MS_CAMPAIGN_OCR_PROCESS_INVALID_PHOTO':
      yield put({ type: errorActionTypes.ERROR_ACTION, error: 'invalidPhotoServerError' });
      break;
    default:
    console.log('une erreur est survenue', error);
    if(error.status === 400 || error.status !== 200) return yield put({ type: errorActionTypes.ERROR_ACTION, error: 'serverError' });
  }
};
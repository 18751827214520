

const sizes = {
  xxxxs: 8,
  xxxs: 10,
  xxs: 12,
  xs: 14,
  small: 16,
  m: 18,
  xm: 20,
  xxm: 22,
  l: 24,
  xl: 28,
  xxl: 34,
  xxxl: 42,
};

export default {
  sizes,
};

// @flow
import { actionTypes as homeActionTypes } from '../home';
import { actionTypes as indexGrabActionTypes } from '../indexGrab';

export const actionTypes = {
  ERROR_ACTION: {
    REQUEST: 'ERROR_ACTION',
  },
};

const initialState = {
  errorText: null,
};

export const errorActionCreator = () => ({
  type: actionTypes.ERROR_ACTION,
});


export const errorReducer = (state = initialState, action) => {
  switch (action.type) {
    case homeActionTypes.GET_HOME_DATA.REQUEST:
    case indexGrabActionTypes.GET_CROPPED_IMAGE_INDEX_ACTION.REQUEST:
    case indexGrabActionTypes.SET_INDEX_ACTION.REQUEST:
      return { ...state, errorText: null }
    case actionTypes.ERROR_ACTION:
    return { ...state, errorText: action.error }
    default:
      return state;
  }
};
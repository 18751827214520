// @flow
import { connect } from 'react-redux';
import ImageScan from './ImageScan';
import { getCroppedImageIndexActionCreator, getIsLoading, getIndexFromCroppedImage, setIndexActionCreator } from '../../../modules/indexGrab';
import { getError } from '../../../modules/error';

const mapStateToProps = (state) => ({
    isApiLoading: getIsLoading(state),
    croppedImageIndex: getIndexFromCroppedImage(state),
    serverError: getError(state),
});
const mapDispatchToProps = ({
    onPostCroppedImage: getCroppedImageIndexActionCreator,
    onUpdateIndex: setIndexActionCreator,
});
export default connect(mapStateToProps, mapDispatchToProps)(ImageScan);

import React from 'react';
import Cropper from 'react-easy-crop'
import imageCompression from 'browser-image-compression';
import Resizer from 'react-image-file-resizer';
import { Button, Modal } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForward from '@material-ui/icons/ArrowForward';
import appStyle from '../../../appStyle';
import translate from '../../../services/Translation';
import getCroppedImg from './cropImage'; //{createImage, getImg}
import { WaterMeterInput } from '../../../components';
import {b64toBlob} from "../../../services/Api"

const IMAGE_WIDTH = 169;
const MODAL_WIDTH = 280;

const styles = {
    cropContainer: {
        position: 'relative',
        height: '60vh',
    },
    nextButton: {
        backgroundColor: appStyle.colors.primary,
        color: appStyle.colors.white,
    },
    arrowForward: {
        fontSize: appStyle.font.sizes.m,
        marginRight: appStyle.margins.m,
        color: appStyle.colors.white,
    },
    fabProgress: {
        color: 'white',
    },
    modalStyle: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: appStyle.colors.white,
      width: MODAL_WIDTH,
      display: 'flex',
      flexDirection: 'column',
      paddingTop: appStyle.paddings.m,
      paddingLeft: appStyle.paddings.l,
      paddingRight: appStyle.paddings.l,
      paddingBottom: appStyle.paddings.m,
      outline: 'none',
      border: '4px solid #000',
      borderColor: appStyle.colors.primary,
  },
  closeButtonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderWidth: 0,
    backgroundColor: 'transparent',
    outline: 'none',
    padding: 0, 
  },
  closeIcon: {
    fontSize: appStyle.font.sizes.xl,
    color: appStyle.colors.warmGrey,
    marginBottom: appStyle.margins.sm,
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  explanationText: {
    marginTop: appStyle.margins.l,
    marginBottom: appStyle.margins.l,
  },
  error: {
    textAlign: 'center',
    color: appStyle.colors.red,
    fontSize: appStyle.font.sizes.xxs,
    marginTop: appStyle.margins.m,
  },
  secondButton: {
    backgroundColor: 'transparent',
    color: appStyle.colors.primary,
    width: '100%',
    marginTop: appStyle.margins.sm,
},
    secondArrowForward: {
      fontSize: appStyle.font.sizes.m,
      marginRight: appStyle.margins.m,
      color: appStyle.colors.primary,
    },
}
type PropsType = {
  onPostCroppedImage: (base64Image: any) => void,
  isApiLoading: Boolean,
  croppedImageIndex: any,
  onUpdateIndex: (index: String) => void,
  serverError: String,
  }; 
class ImageScan extends React.Component {
    constructor() {
        super();
        this.state = {
          picture: null,
          crop: { x: 0, y: 0 },
          zoom: 1,
          aspect: 4 / 1,
          rotation: 0,
          croppedImageToDisplay: null,
          imageWidth: '100%',
          imageHeight: '100%',
          isCropingLoading: false,
          modalVisible: false,
          croppedImageError: null,
        };
        
    }
    props: PropsType;

    componentWillMount() {
        var img = new Image();
        let orientation = 1;
        const imageFile = this.props.location.state ? this.props.location.state.imageFile : null;
        const canvas = document.createElement('canvas')
        imageCompression.getExifOrientation(imageFile).then(res => orientation = res);
        Resizer.imageFileResizer(
          imageFile,
          2000,
          2000,
          'JPEG',
          100,
          0,
          uri => {
              img.onload = () => {
                this.drawRotated(canvas, img, orientation);
              }
              img.src = uri;
          },
      );
    }
    componentDidUpdate(prevProps) {
      if (prevProps.croppedImageIndex !== this.props.croppedImageIndex) return this.setState({ modalVisible: true });
    }
    drawRotated(canvas, img, exifOrientation) {
       var ctx = canvas.getContext('2d');
       var width = img.width,
           height = img.height;
           this.setState({
               imageHeight: img.height,
               imageWidth: img.width,
           })
       // set proper canvas dimensions before transform & export
       if (exifOrientation === 5 || exifOrientation === 6 || exifOrientation === 7 | exifOrientation === 8) {
           canvas.width = height;
           canvas.height = width;
       } else {
           canvas.width = width;
           canvas.height = height;
       }
       // transform context before drawing image
       switch (exifOrientation) {
           case 2:
               ctx.transform(-1, 0, 0, 1, width, 0);
               break;
           case 3:
               ctx.transform(-1, 0, 0, -1, width, height);
               break;
           case 4:
               ctx.transform(1, 0, 0, -1, 0, height);
               break;
           case 5:
               ctx.transform(0, 1, 1, 0, 0, 0);
               break;
           case 6:
               ctx.transform(0, 1, -1, 0, height, 0);
               break;
           case 7:
               ctx.transform(0, -1, -1, 0, height, width);
               break;
           case 8:
               ctx.transform(0, -1, 1, 0, 0, width);
               break;
           default:
               ctx.transform(1, 0, 0, 1, 0, 0);
     }
      
       // Draw img into canvas
    ctx.drawImage(img, 0, 0, width, height);
    return this.setState({ picture: ctx.canvas.toDataURL('image/jpeg'), zoom: 1, crop: { x: 0, y: 0 }, rotation: 0});
   }
   onCropChange = crop => {
    this.setState({ crop })
  }
  onCropComplete = ((croppedArea, croppedAreaPixels) => {
    this.setState({ croppedImage: croppedAreaPixels })
  })


  onZoomChange = zoom => {
    this.setState({ zoom })
  }
  onRotationChange = rotation => {
    this.setState({ rotation })
  }

  showCroppedImage = (async () => {
    this.setState({ isCropingLoading: true })
    this.setState({ croppedImageToDisplay: null })       
    try {
          const croppedImageLib = await getCroppedImg(
            this.state.picture,
            this.state.croppedImage,
            this.state.rotation,
          )
            const imgFullSize = b64toBlob(this.state.picture.split(',')[1])
          try {
            Resizer.imageFileResizer(
              croppedImageLib,
              256,
              64,
              'JPEG',
              100,
              0,
              uri => {
                  Resizer.imageFileResizer(
                      imgFullSize,
                      400,
                      400,
                      'JPEG',
                      200,
                      0,
                      uriFullSize => {
                          this.setState({ croppedImageToDisplay: uri, isCropingLoading: false },
                              () => this.props.onPostCroppedImage(uri.split(',')[1], uriFullSize.split(',')[1]))
                      })

            });
          } catch (e) {
              this.setState({ isCropingLoading: false })
            console.error(e)
          }
    } catch (e) {
        this.setState({ isCropingLoading: false })
      console.error(e)
    }
  })
  submitIndex() {
    this.setState({ croppedImageError: null });
    const cubicMeterValue = this.props.croppedImageIndex ? this.props.croppedImageIndex.readValue.split('.')[0] : 0;
    if (cubicMeterValue === 0 || cubicMeterValue === '') return this.setState({ croppedImageError: translate('croppedImageSendIndexError') })
    this.props.onUpdateIndex(cubicMeterValue, 'PHOTO');
  } 
    render() {
        const { isApiLoading, croppedImageIndex } = this.props;
        const cubicMeterValue = croppedImageIndex ? croppedImageIndex.readValue.split('.')[0] : 0;
        return (
        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: appStyle.paddings.xl, paddingRight: appStyle.paddings.xl }}>
            <Modal
              open={this.state.modalVisible}
              onClose={() => this.setState({ modalVisible: false })}
            >
              <div style={styles.modalStyle}>
                    <button style={styles.closeButtonContainer} onClick={() => this.setState({ modalVisible: false })}>
                        <Close style={styles.closeIcon}/>
                    </button>
                    <div style={styles.modalContent}>
                      <img alt="compteur" style={{ height: 64, width: 256, marginBottom: appStyle.margins.l }} src={this.state.croppedImageToDisplay}/>
                      <WaterMeterInput 
                        croppedImageIndex={cubicMeterValue}
                        isInputEditable={false}
                        getInputRef={(ref) => this.setState({ inputRef: ref })}
                      />
                      {this.state.croppedImageError && <p style={styles.error}>{this.state.croppedImageError}</p>}
                      <p style={styles.explanationText}>{translate('croppedImageExplanation')}</p>
                      <Button 
                          style={{ backgroundColor: appStyle.colors.primary, color: appStyle.colors.white, width: '100%' }}
                          onClick={() => this.submitIndex()}
                          disabled={isApiLoading}
                      >
                        {(isApiLoading) && <CircularProgress size={24} style={styles.fabProgress} />}
                        <ArrowForward style={styles.arrowForward}/>
                        {translate('submitIndexButtonTitle')}
                      </Button>
                      <Button style={styles.secondButton} onClick={() => {
                          this.setState({ croppedImageError: null });
                          this.setState({ modalVisible: false });
                      }}>
                        <ArrowForward style={styles.secondArrowForward}/>
                        {translate('croppAgainButtonTitle')}
                      </Button>
                      <Button style={styles.secondButton} onClick={() =>  {
                          this.setState({ croppedImageError: null });
                          this.props.history.goBack();
                      }}>
                        <ArrowForward style={styles.secondArrowForward}/>
                        {translate('backToIndexGrabButtonTitle')}
                      </Button>
                    </div>
              </div>
            </Modal>
        <img alt="Header" src="assets/images/ressources/headerImage.png" style={{ width: IMAGE_WIDTH, alignSelf: 'center', marginTop: appStyle.margins.m }}/>
        <div style={styles.cropContainer}>
            <Cropper
                image={this.state.picture}
                crop={this.state.crop}
                rotation={this.state.rotation}
                zoom={this.state.zoom}
                aspect={4}
                onCropChange={this.onCropChange}
                onRotationChange={this.onRotationChange}
                onCropComplete={this.onCropComplete}
                onZoomChange={this.onZoomChange}
            />
        </div>
            {this.props.serverError && <p style={styles.error}>{translate(this.props.serverError)}</p>}
            <p style={{ marginTop: appStyle.margins.l, marginBottom: appStyle.margins.l }}>{translate('cropExplanation')}</p>
            <Button style={styles.nextButton} onClick={() => this.showCroppedImage()} disabled={isApiLoading}>
                   {(isApiLoading) && <CircularProgress size={24} style={styles.fabProgress} />}
                    <ArrowForward style={styles.arrowForward}/>
                    {translate('submitCropImage')}
            </Button>
            <Button style={styles.secondButton} onClick={() => this.props.history.goBack()}>
                <ArrowForward style={styles.secondArrowForward}/>
                {translate('tryCroppAgain')}
            </Button>
      </div>
        )
        
    }
}

export default ImageScan; 
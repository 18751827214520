import { takeLatest, call, put, select } from 'redux-saga/effects';
import { actionTypes } from './';
import * as Api from '../../services/Api';
import handleApiException from '../../services/handleApiException';
import { getToken } from '../home';
import history from '../../history';

export function* setIndex(action: any): SagaType {
  try {
    const userToken = yield select(getToken);
    yield put({ type: actionTypes.SET_INDEX_ACTION.API_LOADING_START });
    yield call(Api.setIndex, action.index, userToken || ' ', action.entryMethod);
    yield history.replace('/indexGrabSuccess');
    yield put({ type: actionTypes.SET_INDEX_ACTION.API_LOADING_STOP });
  } catch (error) {
    yield handleApiException(error);
    yield put({ type: actionTypes.SET_INDEX_ACTION.API_LOADING_STOP });  }
}
export function* getCroppedImageIndex(action: any): SagaType {
  try {
    const userToken = yield select(getToken);
    yield put({ type: actionTypes.GET_CROPPED_IMAGE_INDEX_ACTION.API_LOADING_START });
    const response = yield call(Api.getCroppedImageIndex, action.base64Image, userToken || ' ');
    yield put({ type: actionTypes.GET_CROPPED_IMAGE_INDEX_ACTION.SUCCESS, croppedImageIndex: response.body });
    const responseUrl = yield call(Api.getFullSizeImageUploadUrl, userToken || ' ');
    yield call(Api.exportFullSizeImage, action.blobFullSize, responseUrl.body.url.url, userToken || ' ');
    yield put({ type: actionTypes.GET_CROPPED_IMAGE_INDEX_ACTION.API_LOADING_STOP });
  } catch (error) {
    yield handleApiException(error);
    yield put({ type: actionTypes.GET_CROPPED_IMAGE_INDEX_ACTION.API_LOADING_STOP });  }
}
export function* getCroppedImageIndexSagas(): SagaType {
  yield takeLatest(actionTypes.GET_CROPPED_IMAGE_INDEX_ACTION.REQUEST, getCroppedImageIndex);
}
export function* setIndexSagas(): SagaType {
  yield takeLatest(actionTypes.SET_INDEX_ACTION.REQUEST, setIndex);
}

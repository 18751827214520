// @flow
import { connect } from 'react-redux';
import Home from './Home';
import { homeActionCreator, getIsLoading, getProfileInformation, getToken } from '../../modules/home';

const mapStateToProps = state => ({
  isLoading: getIsLoading(state),
  profileInformation: getProfileInformation(state),
  storedToken: getToken(state),
});
const mapDispatchToProps = ({
  onConnect: homeActionCreator,
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);

// @flow
import i18n from '../i18n';
// import numeral from 'numeral';
// import { isNumber } from 'lodash';
// import translations from '../translations';

//I18n.fallbacks = true;
// I18n.translations = translations;
//numeral.locale('fr');

export default function translate(text: string, options: ?Object, language: ?string = 'en') {
  return i18n.t(text);
}

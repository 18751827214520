import React from 'react';
import { Box, Button, Modal } from '@material-ui/core';
import Close from '@material-ui/icons/Close';
import HelpOutline from '@material-ui/icons/HelpOutline';
import CropFree from '@material-ui/icons/CropFree';
import CircularProgress from '@material-ui/core/CircularProgress';
import ArrowForward from '@material-ui/icons/ArrowForward';
import appStyle from '../../appStyle';
import translate from '../../services/Translation';
import { WaterMeterInput, SpeechRecognition } from '../../components';

const IMAGE_WIDTH = 169;
const MODAL_WIDTH = 280;
const SCAN_WIDTH = 58;
const SCAN_HEIGHT = 58;
const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingLeft: appStyle.paddings.l,
      paddingRight: appStyle.paddings.l,
      paddingBottom: appStyle.paddings.l,
    },
    panel: {
        marginVertical: appStyle.margins.sm,
        marginHorizontal: appStyle.margins.sm,
        borderTopRightRadius: appStyle.radiuses.xxl,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: appStyle.radiuses.xxl,
        backgroundColor: appStyle.colors.primary,
        padding: appStyle.paddings.m,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: appStyle.margins.lgg,
    },
    title: {
        color: appStyle.colors.white,
        fontSize: appStyle.font.sizes.small,
    },
    txtBold: {
      fontWeight: 'bold',
    },
    previousIndex: {
      paddingRight: appStyle.paddings.m,
      paddingLeft: appStyle.paddings.m,
      marginTop: appStyle.margins.xs,
      marginBottom: appStyle.margins.l,
    },
    indexSettitle: {
        paddingRight: appStyle.paddings.m,
        paddingLeft: appStyle.paddings.m,
        marginTop: appStyle.margins.xl,
    },
    secondArrowForward: {
        fontSize: appStyle.font.sizes.m,
        marginRight: appStyle.margins.m,
        color: appStyle.colors.primary,
    },
    secondButton: {
        backgroundColor: 'transparent',
        color: appStyle.colors.warmGrey,
        width: "95%",
        marginTop: appStyle.margins.sm,
        textTransform: 'none',
    },
    errorMessage: {
        color: appStyle.colors.red,
        marginTop: appStyle.margins.sm,
        fontSize: appStyle.font.sizes.xxs,
    },
    nextButton: {
        backgroundColor: appStyle.colors.primary,
        color: appStyle.colors.white,
        width: "95%",
        marginTop: appStyle.margins.l,
    },
    arrowForward: {
        fontSize: appStyle.font.sizes.m,
        marginRight: appStyle.margins.m,
        color: appStyle.colors.white,
    },
    fabProgress: {
        color: 'white',
    },
    modalStyle: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: appStyle.colors.white,
      width: MODAL_WIDTH,
      display: 'flex',
      flexDirection: 'column',
      borderTopRightRadius: appStyle.radiuses.xxl,
      borderTopLeftRadius: 0,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: appStyle.radiuses.xxl,
      paddingTop: appStyle.paddings.sm,
      paddingLeft: appStyle.paddings.m,
      paddingRight: appStyle.paddings.sm,
      paddingBottom: appStyle.paddings.m,
      outline: 'none',
  },
  closeButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
      borderWidth: 0,
      backgroundColor: 'transparent',
      outline: 'none',
    },
    closeIcon: {
      fontSize: appStyle.font.sizes.xl,
      color: appStyle.colors.warmGrey,
    },
    modalContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    helpContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      marginTop: appStyle.margins.m,
    },
    helpText: {
      textDecorationLine: 'underline',
      marginLeft: appStyle.margins.sm,
      color: appStyle.colors.warmGrey,
    },
    recognitionContainer:{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: appStyle.paddings.m,
    }, 
    scan: {
        fontSize: appStyle.font.sizes.xxxl,
        color: appStyle.colors.white,
    },
    scanContainer: {
      backgroundColor: appStyle.colors.primary,
      borderRadius: appStyle.radiuses.circle,
      borderWidth: 0,
      width: SCAN_WIDTH,
      height: SCAN_HEIGHT,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    error: {
      textAlign: 'center',
      color: appStyle.colors.red,
      fontSize: appStyle.font.sizes.xxs,
    },
}

type PropsType = {
  profileInformation: any,
  onUpdateIndex: (index: String) => void,
  isApiLoading: Boolean,
  serverError: String,
  storedToken: String,
  onConnect: Function,
};
type StateType = {
    recognitionValue?: String,
    inputRef?: any,
    waterMeterInputValue: String,
    erroMessage: String,
    isErrorVisible: Boolean,
    modalVisible: Boolean,
    waterMeterInputModalVisible: Boolean,
};

class IndexGrab extends React.Component {
    constructor() {
        super();
        this.state = {
            recognitionValue: '',
            waterMeterInputValue: '',
            inputRef: null,
            isErrorVisible: false,
            modalVisible: false,
            erroMessage: null,
            waterMeterInputModalVisible: false,
            initIndexBloc: false,
        };
    }
    props: PropsType;
    state: StateType;

    onFocus() {
          this.setState({ waterMeterInputModalVisible: false }, () => {
            this.setState({ initIndexBloc: true })
            this.setState({ waterMeterInputValue: '' });
          })        
    }
    UNSAFE_componentWillMount() {
        const url_string = window.location.href;
        var url = new URL(url_string);
        var token = url.searchParams.get("token");
        const storedToken = this.props.storedToken || ' ';
        this.props.onConnect(token || storedToken);
    }
    onBlur() {
        const isValid = true;
        const { waterMeterInputValue } = this.state;
        const { profileInformation } = this.props;
        const oldIndexVlue = profileInformation && profileInformation.previousIndexValue ? profileInformation.previousIndexValue : null;
        if (this.state.waterMeterInputValue === '') {
          return this.setState({
            isErrorVisible: true,
            erroMessage: 'errorRequiredIndex',
          });
        }
        if (Number.isNaN(Number(waterMeterInputValue)) || waterMeterInputValue.length > 5) {
          return this.setState({
            isErrorVisible: true,
            erroMessage: 'errorInvalideIndex',
          });
        }
        if (Number(waterMeterInputValue) < Number(oldIndexVlue)) {
            return this.setState({
              isErrorVisible: true,
              erroMessage: 'errorIndexInferiorToLastIndex',
            });
          }
        this.setState({
          isErrorVisible: false,
        });
        return isValid;
    }
    
    onSubmit() {
       const isValid = this.onBlur();
       if (isValid) return this.props.onUpdateIndex(this.state.waterMeterInputValue, this.state.recognitionValue === '' ? 'MANUAL' : 'VOICE');
     }
     onFileChange = async e => {
      if (e.target.files && e.target.files.length > 0) {
          this.props.history.push('/imageScan', { imageFile: e.target.files[0] })
      }
  
  }
    secondaryButtonClick() {
      this.setState({ waterMeterInputModalVisible: true, initIndexBloc: false })
  }
    render() {
        const { profileInformation, isApiLoading } = this.props;
        const holder = profileInformation ? `${profileInformation.firstName} ${profileInformation.lastName}` : '/';
        return (
            <Box style={styles.container}>
              <Modal
                open={this.state.waterMeterInputModalVisible}
                onClose={() => this.setState({ waterMeterInputModalVisible: false })}
              >
                <Box style={styles.modalStyle}>
                    <button style={styles.closeButtonContainer} onClick={() => this.onFocus()}>
                        <Close style={styles.closeIcon}/>
                    </button>
                    <p>{translate('manualInputExplanationTitlte')}</p>
                </Box>
              </Modal>
              <Modal
                open={this.state.modalVisible}
                onClose={() => this.setState({ modalVisible: false })}
              >
                <Box style={styles.modalStyle}>
                    <button style={styles.closeButtonContainer} onClick={() => this.setState({ modalVisible: false })}>
                        <Close style={styles.closeIcon}/>
                    </button>
                    <Box style={styles.modalContent}>
                      <p>{translate('modalInformation')}</p>
                    </Box>
                </Box>
              </Modal>
                <img alt="Header" src="assets/images/ressources/headerImage.png" style={{ width: IMAGE_WIDTH, marginTop: appStyle.margins.sm, marginBottom: appStyle.margins.sm }}/>
                <Box style={styles.panel}>
                  <p style={styles.title}>{holder}</p>
                </Box>
                {/* Last Index Value displayed if exists*/}
                {profileInformation && profileInformation.previousIndexValue !== undefined && profileInformation.previousIndexValue !== '' ? (
                  <p style={styles.previousIndex}>
                      {translate('previousIndexTitle')} <span style={styles.txtBold}>{profileInformation.previousIndexValue}</span> m<sup>3</sup>
                  </p>
                ) : null}
                <WaterMeterInput 
                    speechRecognitionValue={this.state.recognitionValue}
                    getInputRef={(ref) => this.setState({ inputRef: ref })}
                    onChangeText={(value) => this.setState({ waterMeterInputValue: value})}
                    isBlocIndexReseting={this.state.initIndexBloc}
                />
                {this.state.isErrorVisible && <p style={styles.errorMessage}>{translate(this.state.erroMessage)}</p>}
                <button style={{ outline: 'none', borderWidth: 0, backgroundColor: 'transparent' }} onClick={() => this.setState({ modalVisible: true })}>
                  <Box style={styles.helpContainer}>
                    <HelpOutline style={{ color: appStyle.colors.warmGrey }}/>
                    <p style={styles.helpText}>
                      {translate('helpText')}
                  </p>
                </Box>
                </button>
                <p style={styles.indexSettitle}>
                    {translate('speechRecognitionTitlePart1')}
                </p>
                <div style={styles.recognitionContainer}>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: appStyle.margins.xl}}>
                  <input id="cameraInput" type="file" accept="image/*" capture onChange={this.onFileChange} style={{ display: 'none' }}/>
                    <label
                      style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', textDecoration: 'underline', fontWeight: 'bold', cursor: 'pointer' }} 
                      for="cameraInput"
                    >
                      <div style={styles.scanContainer} for="cameraInput">
                        <CropFree style={styles.scan}/>
                      </div>
                      {translate('scanInput')}
                    </label>
                  </div>
                  <SpeechRecognition getValue={(value) => this.setState({ recognitionValue: value })}/>
                </div>
                <p style={styles.indexSettitle}>
                    {translate('manualIndexSetTitle')}
                </p>
                <Button style={styles.secondButton} onClick={() => {this.state.inputRef && this.secondaryButtonClick()}}>
                <ArrowForward style={styles.secondArrowForward}/>
                {translate('manualIndexsetChoice')}
                </Button>
                {this.props.serverError && <p style={styles.error}>{translate(this.props.serverError)}</p>}
                <Button style={styles.nextButton} onClick={() => this.onSubmit()} disabled={isApiLoading}>
                   {isApiLoading && <CircularProgress size={24} style={styles.fabProgress} />}
                    <ArrowForward style={styles.arrowForward}/>
                    {translate('submitIndexButtonTitle')}
                </Button>
            </Box>
        )
    }
}

export default IndexGrab; 
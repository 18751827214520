// @flow

export const actionTypes = {
  GET_HOME_DATA: {
    REQUEST: 'GET_HOME_DATA_REQUEST',
    SUCCESS: 'GET_HOME_DATA_SUCCESS',
    API_LOADING_START: 'GET_HOME_DATA_API_LOADING_START',
    API_LOADING_STOP: 'GET_HOME_DATA_API_LOADING_STOP',
  },
};

const initialState = {
  isApiLoading: false,
  profileInformation: null,
  token: null,
};

export const homeActionCreator = (token: String) => ({
  type: actionTypes.GET_HOME_DATA.REQUEST,
  token,
});


export const homeReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_HOME_DATA.API_LOADING_START:
      return { ...state, isApiLoading: true }
    case actionTypes.GET_HOME_DATA.API_LOADING_STOP:
        return { ...state, isApiLoading: false }
    case actionTypes.GET_HOME_DATA.SUCCESS:
    return { ...state, profileInformation: action.profileInformation , token: action.token}
    default:
      return state;
  }
};

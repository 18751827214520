import { takeLatest, call, put } from 'redux-saga/effects';
import { actionTypes } from './';
import * as Api from '../../services/Api';
import handleApiException from '../../services/handleApiException';

export function* confirmation(action: any): SagaType {
  try {
    const { token } = action;
    yield put({ type: actionTypes.GET_HOME_DATA.API_LOADING_START });
    const response = yield call(Api.confirm, token);
    yield put({ type: actionTypes.GET_HOME_DATA.API_LOADING_STOP });
    yield put({ type: actionTypes.GET_HOME_DATA.SUCCESS, profileInformation: response.body, token });
  } catch (error) {
    yield handleApiException(error);
    yield put({ type: actionTypes.GET_HOME_DATA.API_LOADING_STOP });  }
}

export function* homeSagas(): SagaType {
  yield takeLatest(actionTypes.GET_HOME_DATA.REQUEST, confirmation);
}

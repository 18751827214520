// @flow

const blue = {
  blue_1: 'rgb(0, 174, 199)',
};
const greys = {
  grey_1: 'rgb(242, 242, 243)',
  grey_2: 'rgb(145, 145, 145)',
  grey_3: 'rgb(138, 145, 164)',
};
const reds = {
  red_1: 'rgb(233, 95, 71)',
  default: 'red',
};
const white = 'white';
const black = 'black';

export default {
 primary: blue.blue_1,
 lightGrey: greys.grey_1,
 warmGrey: greys.grey_2,
 blueGrey: greys.grey_3,
 white,
 black,
 literBlocColor: reds.red_1,
 red: reds.default,
};

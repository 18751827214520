import React from 'react';
import { Box } from '@material-ui/core';
import appStyle from '../../appStyle';
import translate from '../../services/Translation';

const HEADER_IMAGE_WIDTH = 169;
const IMAGE_WIDTH = 52;
const IMAGE_HEIGHT = 72;
const CONTAINER_HEIGHT = 343;

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: appStyle.paddings.l,
        paddingRight: appStyle.paddings.l,
        paddingBottom: appStyle.paddings.l,
      },
    title: {
          color: appStyle.colors.white,
          fontSize: appStyle.font.sizes.small,
    },
    informationContainer: {
        backgroundColor: appStyle.colors.lightGrey,
        display: 'flex',
        flexDirection: 'column',
        height: CONTAINER_HEIGHT,
        paddingLeft: appStyle.paddings.l,
        paddingTop: appStyle.paddings.xxl,
        width: '100%',
    },
    errorImageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between'
    },
    image: {
        height: IMAGE_HEIGHT,
        width: IMAGE_WIDTH,
    },
}
type PropsType = {
    error: any,
  }; 
class HandleException extends React.Component {
    props: PropsType;
    render() {
        return (
            <Box style={styles.container}>
                <img alt="Header" src="assets/images/ressources/headerImage.png" style={{ width: HEADER_IMAGE_WIDTH, marginTop: appStyle.margins.m }}/>
            <Box style={styles.informationContainer}>
              <Box style={styles.errorImageContainer}>
                <Box style={{ marginRight: appStyle.margins.l }}>
                   <p>{translate(this.props.error)}</p>
                </Box>
                <img alt="error" src="assets/images/ressources/error.png" style={styles.image}/>
              </Box>
            </Box>
            </Box>
        )
    }
}

export default HandleException; 
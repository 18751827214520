import React from 'react';
import { Box } from '@material-ui/core';
import appStyle from '../../../appStyle';
import translate from '../../../services/Translation';

const IMAGE_HEIGHT = 72;
const IMAGE_WIDTH = 169;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
    },
    materImage: {
        height: IMAGE_HEIGHT
    },
    infoContainer: {
        backgroundColor: appStyle.colors.lightGrey,
        paddingTop: appStyle.paddings.lgg,
        paddingBottom: appStyle.paddings.lgg,
        paddingLeft: appStyle.paddings.lgg,
        marginLeft: appStyle.margins.l,
        marginRight: appStyle.margins.l,
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: appStyle.paddings.l,
    },
}
class WrongHolder extends React.Component {
    render() {
        return (
            <Box style={styles.container}>
              <img alt="Header" src="assets/images/ressources/headerImage.png" style={{ width: IMAGE_WIDTH, marginTop: appStyle.margins.m  }}/>
                <Box style={styles.infoContainer}>
                    <Box style={styles.imageContainer}>
                    <Box>
                    <p>{translate('notMeTitle')}</p>
                    <p style={{ marginTop: appStyle.margins.m }}>{translate('notMeExplanationTitle')}</p>
                    </Box>
                    <img alt="sad" src="assets/images/ressources/sad.png" style={styles.materImage}/>
                    </Box>
                </Box>
            </Box>
        )
    }
}

export default WrongHolder; 
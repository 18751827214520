// @flow
import { connect } from 'react-redux';
import HandleException from './HandleException';
import { getError } from '../../modules/error';

const mapStateToProps = state => ({
  error: getError(state),
});

export default connect(mapStateToProps, null)(HandleException);

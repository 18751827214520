import React from 'react';
// import { Box } from '@material-ui/core';
import appStyle from '../../../appStyle';

const CONTAINER_WIDTH = 24;
const CONTAINER_HEIGHT = 36;

const styles = {
  container: {
    display: 'flex',
    backgroundColor: appStyle.colors.black,
    width: CONTAINER_WIDTH,
    height: CONTAINER_HEIGHT,
    borderRadius: appStyle.radiuses.m,
    justifyContent: 'center',
    alignItems: 'center',
    borderWidth: 0,
    outline: 'none',
  },
  value: {
    color: appStyle.colors.white,
    fontSize: appStyle.font.sizes.l,
  },
};
type PropsType = {
  value: number,
  isLiterBloc?: boolean,
  onPress: ()=> void,
};

class IndexBloc extends React.Component {
  static defaultProps = {
    isLiterBloc: false,
  };

  props: PropsType;

  render() {
    let containerStyle = styles.container;
    if (this.props.isLiterBloc) {
      containerStyle = { ...containerStyle, backgroundColor: appStyle.colors.literBlocColor };
    }
    return (
      <button style={containerStyle} onClick={this.props.onPress}>
          {this.props.value
                && <p style={styles.value}>{this.props.value }</p>
          }
          {this.props.isLiterBloc
                && <p style={styles.value}>-</p>
          }
      </button>
    );
  }
}
export default IndexBloc;

// @flow
import { connect } from 'react-redux';
import IndexGrab from './IndexGrab';
import {getProfileInformation, getToken, homeActionCreator} from '../../modules/home'
import { setIndexActionCreator, getIsLoading } from '../../modules/indexGrab';
import { getError } from '../../modules/error';

const mapStateToProps = (state) => ({
  profileInformation: getProfileInformation(state),
  isApiLoading: getIsLoading(state),
  serverError: getError(state),
    storedToken: getToken(state),
});
const mapDispatchToProps = ({
    onConnect: homeActionCreator,
   onUpdateIndex: setIndexActionCreator,
});
export default connect(mapStateToProps, mapDispatchToProps)(IndexGrab);

import React from 'react';
import { Box, Modal } from '@material-ui/core'
import Mic from '@material-ui/icons/Mic';
import Close from '@material-ui/icons/Close';
import appStyle from '../../appStyle';
import translate from '../../services/Translation';
import { flash } from 'react-animations';
import Radium, {StyleRoot} from 'radium';
import { LANG } from './../../environment';

let _RECOGNITION = null;
const MIC_WIDTH = 58;
const MIC_HEIGHT = 58;
const MODAL_WIDTH = 280;
const ANIMATED_IMAGE = 89;
const styles = {
    mic: {
        fontSize: appStyle.font.sizes.xxxl,
        color: appStyle.colors.white,
    },
    micButton: {
        borderRadius: appStyle.radiuses.circle,
        backgroundColor: appStyle.colors.primary,
        width: MIC_WIDTH,
        height: MIC_HEIGHT,
        borderWidth: 0,
        outline: 'none',
        cursor: 'pointer',
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        alignSelf: 'center',
    },
    modalStyle: {
        position: 'absolute',
        left: '50%',
        top: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: appStyle.colors.white,
        width: MODAL_WIDTH,
        display: 'flex',
        flexDirection: 'column',
        borderTopRightRadius: appStyle.radiuses.xxl,
        borderTopLeftRadius: 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: appStyle.radiuses.xxl,
        paddingTop: appStyle.paddings.sm,
        paddingLeft: appStyle.paddings.sm,
        paddingRight: appStyle.paddings.sm,
        paddingBottom: appStyle.paddings.m,
        outline: 'none',
    },
    closeButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        borderWidth: 0,
        backgroundColor: 'transparent',
        outline: 'none',
      },
      closeIcon: {
        fontSize: appStyle.font.sizes.xl,
        color: appStyle.colors.warmGrey,
      },
      modalContent: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
      bounce: {
        marginTop: appStyle.margins.m,
        marginBottom: appStyle.margins.m,
        animation: '2s infinite',
        animationName: Radium.keyframes(flash, 'flash'),
        height: ANIMATED_IMAGE,
        width: ANIMATED_IMAGE,
      },
      modalTitle: {
          textAlign: 'center',
      },
}

type PropsType = {
  getValue: (indexValue: String) => void,

}; 
type StateType = {
    modalVisible: Boolean,
    speechResult: String,
    unSuportedRecognitonModalVisible: Boolean,
  }; 

class SpeechRecognition extends React.Component {
    constructor() {
        super();
        const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
        if (SpeechRecognition) {
        _RECOGNITION = new SpeechRecognition();
        _RECOGNITION.onresult = this.speechResult.bind(this);
        _RECOGNITION.onspeechstart = this.speechStart.bind(this);
        _RECOGNITION.onspeechend = this.speechEnd.bind(this);
        _RECOGNITION.interimResults = true;
        _RECOGNITION.continuous = false;
        _RECOGNITION.onstart = this.start.bind(this);
        _RECOGNITION.onend = this.stop.bind(this);
        _RECOGNITION.lang = LANG;
        }
        this.state = {
            modalVisible: false,
            unSuportedRecognitonModalVisible: false,
            speechResult: '',
        };
    }
    props: PropsType;

    state: StateType;

    speechResult(e) {
        const speechToText = e.results[0][0].transcript.replace(/\s/g, '').replace(/\D/g, '');
       this.setState({ speechResult: speechToText });
       this.props.getValue(speechToText);
    }
    speechStart() {
    } 
    speechEnd() {
    } 
    start () {
        this.setState({ speechResult: ''}, () => this.onModalOpen());
    }
    stop () {
        this.onModalClose();
    }
    onModalOpen() {
      this.setState({ modalVisible: true });
    }
    onModalClose() {
        this.setState({ modalVisible: false });
    }
    recognitionServiceStart() {
        if (_RECOGNITION) {
            _RECOGNITION.start();
        } else {
            this.setState({ unSuportedRecognitonModalVisible: true })
        }
    }
    render() {
        return (
            <Box style={styles.container}>
                <Modal
                  open={this.state.modalVisible}
                  onClose={() => _RECOGNITION.stop()}
                >
                    <Box style={styles.modalStyle}>
                        <button style={styles.closeButtonContainer} onClick={() => _RECOGNITION.stop()}>
                            <Close style={styles.closeIcon}/>
                        </button>
                        <Box style={styles.modalContent}>
                           <p>{translate('recordingInProgress')}</p>
                           <StyleRoot>
                            <div style={styles.bounce}>
                                <img alt="microphone" src="assets/images/ressources/animatedMicro.png" />
                            </div>
                            </StyleRoot>
                            <p>{this.state.speechResult}</p>
                        </Box>
                    </Box>
                </Modal>
                <Modal
                  open={this.state.unSuportedRecognitonModalVisible}
                  onClose={() => this.setState({ unSuportedRecognitonModalVisible: false })}
                >
                    <Box style={styles.modalStyle}>
                        <button style={styles.closeButtonContainer} onClick={() => this.setState({ unSuportedRecognitonModalVisible: false })}>
                            <Close style={styles.closeIcon}/>
                        </button>
                        <Box style={styles.modalContent}>
                           <p style={styles.modalTitle}>{translate('unSupportedRecognitionServiceTitle')}</p>
                           <p style={{ ...styles.modalTitle, marginTop: appStyle.margins.m }}>{translate('manualIndexSetIncitation')}</p>
                        </Box>
                    </Box>
                </Modal>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', cursor: 'pointer' }} onClick={() => this.recognitionServiceStart()}>
                    <button style={styles.micButton}>
                        <Mic style={styles.mic}/>
                    </button>
                    <p 
                        style={{ textAlign: 'center', textDecoration: 'underline', fontWeight: 'bold' }}
                    >
                        {translate('voiceInput')}
                    </p>
                </div>
            </Box>
        )
    }
}

export default SpeechRecognition; 
import React from 'react';
import { Box } from '@material-ui/core';
import IndexBloc from './components/IndexBloc';

const INPUT_WIDTH = 1;
const INPUT_HEIGHT = 48;
const CONTAINER_WIDTH = 230;

const styles = {
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
  },
  containerBloc: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-around',
    width: CONTAINER_WIDTH,
  },
  textInput: {
    position: 'absolute',
    width: INPUT_WIDTH,
    height: INPUT_HEIGHT,
    outline: 'none',
    border: 'none',
    right: 0,
    color: 'transparent',
    backgroundColor: 'transparent',
    caretColor: 'transparent',
  },
};
type PropsType = {
  onChangeText: (value) => void,
  onBlur: () => void,
  speechRecognitionValue: Boolean,
  getInputRef: (ref) => void,
  croppedImageIndex?: String,
  isInputEditable?: Boolean,
  style?: any,
  isBlocIndexReseting?: Boolean,
};
type StateType = {
    indexValue: number,
};

class WaterMeterInput extends React.Component {
  static defaultProps = {
     croppedImageIndex: null,
     isInputEditable: true,
     isBlocIndexReseting: false,
     style: {},
  };
  constructor(props) {
    super(props);
    this.state = {
      indexValue: '',
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
     if (prevProps.speechRecognitionValue !== this.props.speechRecognitionValue) this.onChangeText(this.props.speechRecognitionValue);
     if ((prevProps.isBlocIndexReseting !== this.props.isBlocIndexReseting) && this.props.isBlocIndexReseting ) return this.focusInput();
  }

  componentDidMount() {
    if (!this.props.isInputEditable && this.props.croppedImageIndex) this.onChangeText(this.props.croppedImageIndex.toString());
    this.props.getInputRef(this._input);
  }

  state: StateType;

  props: PropsType;

  focusInput() {
    if (!this.props.isInputEditable) return;
    this.setState({
      indexValue: '',
    }, () => {
      this._input.value = '';
      this.onChangeText('')
      this._input.focus(); });

  }

  handleChange = (e) => {
    this.onChangeText(e.target.value);
  }
  onChangeText(value) {
    if (this.props.onChangeText) this.props.onChangeText(value);
    this.setState({
      indexValue: value,
    });
  }

  onBlur = (e) => {
    this.props.onBlur();
  }

  _input = null;
  render() {
    const arrayIndexValue = this.state.indexValue.split('').reverse();
    const arrayIndexPosition = [4, 3, 2, 1, 0];
    const arrayIndexLiterPositon = ['box_0', 'box_1', 'box_2'];
    const containerBlocStyle = { ...styles.containerBloc, ...this.props.style };
    return (
      <Box style={styles.container}>
        <Box style={containerBlocStyle}>
          {arrayIndexPosition.map(position => (
            <IndexBloc
              key={position}
              value={arrayIndexValue[position]}
              onPress={() => this.focusInput()}
            />
          ))}
          <input
            type="number"
            name=""
            ref={(input) => { this._input = input; }}
            style={styles.textInput}
            onChange={this.handleChange}
            disabled={!this.props.isInputEditable}
          />
          {arrayIndexLiterPositon.map(key => (
            <IndexBloc key={key} isLiterBloc />
          ))}
        </Box>
      </Box>
    );
  }
}
export default WaterMeterInput;

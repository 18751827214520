
import { fork } from 'redux-saga/effects';
import { homeSagas } from './home';
import { setIndexSagas, getCroppedImageIndexSagas } from './indexGrab';

export default function* rootSaga() {
    yield fork(homeSagas);
    yield fork(setIndexSagas);
    yield fork(getCroppedImageIndexSagas);
}

// @flow

export const actionTypes = {
  SET_INDEX_ACTION: {
    REQUEST: 'SET_INDEX_ACTIONREQUEST',
    SUCCESS: 'SET_INDEX_ACTION_SUCCESS',
    API_LOADING_START: 'SET_INDEX_ACTION_API_LOADING_START',
    API_LOADING_STOP: 'SET_INDEX_ACTION_API_LOADING_STOP',
  },
  GET_CROPPED_IMAGE_INDEX_ACTION: {
    REQUEST: 'GET_CROPPED_IMAGE_INDEX_ACTION_REQUEST',
    SUCCESS: 'GET_CROPPED_IMAGE_INDEX_ACTION_SUCCESS',
    API_LOADING_START: 'GET_CROPPED_IMAGE_INDEX_ACTION_API_LOADING_START',
    API_LOADING_STOP: 'GET_CROPPED_IMAGE_INDEX_ACTION_API_LOADING_STOP',
  },
};

const initialState = {
  isApiLoading: false,
  croppedImageIndex: null,
};

export const setIndexActionCreator = (index: String, entryMethod: String) => ({
  type: actionTypes.SET_INDEX_ACTION.REQUEST,
  index,
  entryMethod
});
export const getCroppedImageIndexActionCreator = (base64Image: String, blobFullSize: String) => ({
  type: actionTypes.GET_CROPPED_IMAGE_INDEX_ACTION.REQUEST,
  base64Image,
  blobFullSize
});

export const setIndexReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_INDEX_ACTION.API_LOADING_START:
    case actionTypes.GET_CROPPED_IMAGE_INDEX_ACTION.API_LOADING_START:
      return { ...state, isApiLoading: true }
    case actionTypes.SET_INDEX_ACTION.API_LOADING_STOP:
    case actionTypes.GET_CROPPED_IMAGE_INDEX_ACTION.API_LOADING_STOP:
        return { ...state, isApiLoading: false }
    case actionTypes.GET_CROPPED_IMAGE_INDEX_ACTION.SUCCESS:
    return { ...state, croppedImageIndex: action.croppedImageIndex }
    default:
      return state;
  }
};

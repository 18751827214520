import React from 'react';
import { Box } from '@material-ui/core';
import appStyle from '../../../appStyle';
import translate from '../../../services/Translation';

const IMAGE_WIDTH = 169;
const SUCCESS_IMAGE_WIDTH = 70;
const SUCCESS_IMAGE_HEIGHT = 70;
const CONTAINER_HEIGHT = 343;

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingLeft: appStyle.paddings.l,
        paddingRight: appStyle.paddings.l,
        paddingBottom: appStyle.paddings.l,
      },
    panel: {
          marginVertical: appStyle.margins.sm,
          marginHorizontal: appStyle.margins.sm,
          borderTopRightRadius: appStyle.radiuses.xxl,
          borderTopLeftRadius: 0,
          borderBottomRightRadius: 0,
          borderBottomLeftRadius: appStyle.radiuses.xxl,
          backgroundColor: appStyle.colors.primary,
          padding: appStyle.paddings.m,
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: appStyle.margins.lgg,
    },
    title: {
          color: appStyle.colors.white,
          fontSize: appStyle.font.sizes.small,
    },
    informationContainer: {
        backgroundColor: appStyle.colors.lightGrey,
        display: 'flex',
        flexDirection: 'column',
        height: CONTAINER_HEIGHT,
        paddingLeft: appStyle.paddings.l,
        paddingTop: appStyle.paddings.xxl,
    },
    succesImageContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: appStyle.paddings.sm,
    },
    materImage: {
        height: SUCCESS_IMAGE_HEIGHT,
        width: SUCCESS_IMAGE_WIDTH,
    },
}
type PropsType = {
    profileInformation: any,
  }; 
class IndexGrabSuccess extends React.Component {
    props: PropsType;
    render() {
        const { profileInformation } = this.props;
        const holder = profileInformation ? `${profileInformation.firstName} ${profileInformation.lastName}` : '/';
        return (
            <Box style={styles.container}>
                <img alt="Header" src="assets/images/ressources/headerImage.png" style={{ width: IMAGE_WIDTH,  }}/>
                <Box style={styles.panel}>
                  <p style={styles.title}>{holder}</p>
                </Box>
            <Box style={styles.informationContainer}>
              <Box style={styles.succesImageContainer}>
                <img alt="success" src="assets/images/ressources/success.png" style={styles.materImage}/>
                <Box style={{ marginLeft: appStyle.margins.l }}>
                   <p>{translate('indexGrabSuccessTitle')}</p>
                   <p style={{ marginTop: appStyle.margins.m }}>{translate('indexGrabSuccessThanks')}</p>
                </Box>
              </Box>
            </Box>
            </Box>
        )
    }
}

export default IndexGrabSuccess; 
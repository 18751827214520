import request from 'superagent';
import { END_POINT, API_KEY, BRAND_KEY } from '../environment';

export const confirm = (token: String) => (
    request
      .post(`${END_POINT}/campaign/confirm`)
      .set('x-api-key', API_KEY) 
      .query({
          bk: BRAND_KEY,
          clt: token,
          tsk: + new Date()
        })
  );
  export const setIndex = (index: String, token: String, entryMethod: String) => (
    request
      .post(`${END_POINT}/campaign/sendindex`)
      .set('x-api-key', API_KEY) 
      .query({
          bk: BRAND_KEY,
          clt: token,
          tsk: + new Date(),
        })
      .send({ indexValue: Number(index), entryMethod })
  );

export const getFullSizeImageUploadUrl = (token: String) => (
    request
        .get(`${END_POINT}/campaign/imgurl`)
        .set('x-api-key', API_KEY)
        .query({
            bk: BRAND_KEY,
            clt: token,
            tsk: + new Date(),
        })
);
export const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
}
export const exportFullSizeImage = (base64Image: any, url:String, token: String) => (
    new Promise((resolve, reject) => {
        const blob = b64toBlob(base64Image)
        fetch(url, {
            method: 'PUT',
            body: blob,
            headers:{"Content-Type":"image/jpeg"}
        }).then(res => {
            if(res.ok) {
                resolve(res);
            }
            else {
                reject(res);
            }
        }).catch(error => reject(error))
    }))

  export const getCroppedImageIndex = (base64Image: any, token: String) => (
    request
      .post(`${END_POINT}/campaign/ocr`)
      .set('x-api-key', API_KEY) 
      .query({
          bk: BRAND_KEY,
          clt: token,
          tsk: + new Date(),
        })
      .send(base64Image)
  );
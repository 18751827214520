import React from 'react';
import { Router, Route, Switch} from 'react-router-dom';
import { Home, WrongHolder, IndexGrab, IndexGrabSuccess, HandleException, NoMatchingPageError, ImageScan } from './pages';
import history from './history';

class App extends React.Component {
    render() {
        return (
        <Router history={history}>
            <Switch>
            <Route path="/" exact component={Home}/>
            <Route path="/home" component={Home}/>
            <Route path="/wrongHolder" component={WrongHolder}/>
            <Route path="/indexGrab" component={IndexGrab}/>
            <Route path="/indexGrabSuccess" component={IndexGrabSuccess}/>
            <Route path="/imageScan" component={ImageScan}/>
            <Route path="/handleException" component={HandleException}/>
            <Route component={NoMatchingPageError}/>
            </Switch>
       </Router>        
       )
    }
}

export default App; 
import React from 'react';
import { Box, Button } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import appStyle from '../../appStyle';
import translate from '../../services/Translation';

const IMAGE_HEIGHT = 72;
const IMAGE_WIDTH = 169;

const styles = {
    container: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        paddingBottom: appStyle.paddings.l,
        marginLeft: appStyle.margins.l,
        marginRight: appStyle.margins.l,
    },
    materImage: {
        height: IMAGE_HEIGHT
    },
    infoContainer: {
        backgroundColor: appStyle.colors.lightGrey,
        paddingTop: appStyle.paddings.lgg,
        paddingLeft: appStyle.paddings.l,
        paddingBottom: appStyle.paddings.lgg,
    },
    imageContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: appStyle.paddings.l,
    },
    informationContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingRight: appStyle.paddings.l,
    },
    userInformation: {
        color: appStyle.colors.warmGrey,
        fontSize: appStyle.font.sizes.xs,
    },
    rgpd: {
        color: appStyle.colors.blueGrey,
        fontSize: appStyle.font.sizes.xs,
        marginTop: appStyle.margins.l,
        marginBottom: appStyle.margins.m,
    },
    arrowForward: {
        fontSize: appStyle.font.sizes.m,
        marginRight: appStyle.margins.m,
        color: appStyle.colors.white,
    },
    secondArrowForward: {
        fontSize: appStyle.font.sizes.m,
        marginRight: appStyle.margins.m,
        color: appStyle.colors.primary,
    },
    nextButton: {
        backgroundColor: appStyle.colors.primary,
        color: appStyle.colors.white,
        width: "95%",
    },
    secondButton: {
        backgroundColor: 'transparent',
        color: appStyle.colors.primary,
        width: "95%",
        marginTop: appStyle.margins.sm,
    },
}
type PropsType = {
    isLoading: Boolean,
    onConnect: (token: String) => void,
    profileInformation: any,
    storedToken: String,
};
class Home extends React.Component {
    props: PropsType;

    async componentDidMount() {
        const url_string = window.location.href;
        var url = new URL(url_string);
        var token = url.searchParams.get("token");
        if (token === null || token === undefined || token === "") {
            token = "invalid";
        }
        // const storedToken = this.props.storedToken || ' '; // TODO: On veut pas récupérer l'ancien token !
        this.props.onConnect(token);
     }
    render() {
        const { profileInformation } = this.props;
        const holder = profileInformation ? `${profileInformation.firstName} ${profileInformation.lastName}` : '/';
        const contractNumber = profileInformation ? profileInformation.contractNumber: '/';
        const meterNumber = profileInformation ? profileInformation.pddNumber: '/';
        return (
            <Box style={styles.container}>
                <img alt="hearder" src="assets/images/ressources/headerImage.png" style={{ width: IMAGE_WIDTH, marginTop: appStyle.margins.m, marginBottom: appStyle.margins.xsm }}/>
            <Box style={styles.infoContainer}>
                <Box style={styles.imageContainer}>
                <Box>
                <p>{translate('homeWelcomeTitle')}</p>
                <p style={{ marginTop: appStyle.margins.m }}>{translate('homeWelcomeExplanationTitle')}</p>
                </Box>
                <img alt="compteur" src="assets/images/ressources/meter.png" style={styles.materImage}/>
                </Box>
            <Box style={styles.informationContainer}>
            <p style={styles.userInformation}>{translate('holder')}</p>
            <p>{holder}</p>
            </Box>
            <Box style={styles.informationContainer}>
            <p style={styles.userInformation}>{translate('contractNumber')}</p>
            <p>{contractNumber}</p>
            </Box>
            <Box style={styles.informationContainer}>
            <p style={styles.userInformation}>{translate('meterNumber')}</p>
            <p>{meterNumber}</p>
            </Box>
            <p style={styles.rgpd}>{translate('rgpdText')}</p>
            <Button style={styles.nextButton} onClick={() => this.props.history.push('/indexGrab')}>
              <ArrowForward style={styles.arrowForward}/>
              {translate('nextButtonTitle')}
            </Button>
                <Button style={styles.secondButton} onClick={() => this.props.history.push('/wrongHolder')}>
                <ArrowForward style={styles.secondArrowForward}/>
                {translate('notMeButtonTitle')}
                </Button>
                <p 
                style={{ 
                    color: appStyle.colors.warmGrey,
                    fontStyle: 'italic',
                    fontSize: appStyle.font.sizes.xxs,
                }}
                >
                {translate('appVersion')}
              </p>
            </Box>
            </Box>
        )
    }
}

export default Home; 